<template>
    <div class="wrapper">
        <div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('/img/pages/about-us-autolearnify-27.jpg')">
            </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h1 class="title">About Us</h1>
                        <h4>This is the place to learn more about AutoLearnify.</h4>
                    </div>
                </div>
            </div>
        </div><!--<h4>Our mission is what drives us to do everything possible to expand human learning potential.</h4>-->
        <div class="section" style="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 mr-auto ml-auto text-center" style="margin-top: -30px;">
                            <h2 class="title">This site is under construction...</h2>
                            <br>
                        </div>
                        <div class="col-md-8 ml-auto mr-auto">

                            <!--<h4 style="text-align: center"></h4>-->
                            AutoLearnify origins from a thesis written at the <a href="https://iimt.ch" target="_blank">international institute of management in technology (iimt)</a>.
                            <br><br>
                            The app was designed based on several studies investigating the effectiveness of several learning techniques such as spaced repetition, practice testing and....




                            <!--Our goal is:
                            <blockquote class="blockquote">TO EMPOWER LEARNERS TO LEARN EFFECTIVELY WITH THE HELP OF AI.</blockquote>
-->
                            .

                            <!--AutoLearnify is the easy, free and effective way to studying.-->
<!--
            <h4>We create tools that expand human learning potential in a more fulfilling way to work and live.</h4>
            <p>AutoLearnify is passionate about making learning more effective. Soon we will release AutoLearnify for the web, iPhone and iPad.</p>

			<h2>Our Goal:</h2>

			<p>To learn more about what is coming next, checkout our roadmap: <a href="https://trello.com/b/u0d82wh4/roadmap">Trello: Roadmap Smart Items</a>.
			Our product roadmap is the place to find out what’s new and what’s coming next. Let us know what you think by providing feedback and voting on features.</p>
			
			<h2>Our guiding principles:</h2>
                            <p>Our idea from day one has been to make automatic practice testing possible for more effective learning. </p>
                            <ul><li>We strive to be a progressive force behind the axiom of “knowledge is power.” </li>
                                <li>We want to be a good place to learn for everyone.</li>
                                <li>We are excited about building strong relationships with everyone we interact with.</li>
                            </ul>
 -->
            </div>
        </div>
        </div></div>


        <div class="projects-4" data-background-color="gray">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 px-0">
                        <card type="background" class="card-fashion"
                              style="background-image: url('/img/project16.jpg')">
                            <div class="card-body">
                                <div class="card-title text-left">
                                    <h2>
                                        <a href="#">
                                            This site is under construction...
                                        </a>
                                    </h2>
                                </div>
                                <div class="card-footer text-left">
                                    <div class="stats">
                                            <span>
                                                <i class="now-ui-icons objects_globe"></i>This site is under construction...
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-6 px-0">
                        <div class="card-container">
                            <div class="card card-fashion">
                                <div class="card-title">
                                    <a href="#">
                                        <h4>
                                            <a href="#">
                                                The app was designed based on several studies investigating the effectiveness of several learning techniques such as spaced repetition, practice testing and.... .
                                            </a>
                                        </h4>
                                    </a>
                                </div>
                                <div class="card-body">
                                    <div class="card-footer">
                                        <div class="stats">
                                                <span>
                                                    <i class="now-ui-icons education_paper"></i> AutoLearnify ― Learn Smarter
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <card type="background" class="card-fashion"
                                  style="background-image: url('/img/project15.jpg')">
                            </card>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 px-0">
                        <div class="card-container">
                            <card type="background" class="card-fashion"
                                  style="background-image: url('/img/project17.jpg')">
                            </card>
                            <div class="card card-fashion arrow-left">
                                <div class="card-title">
                                    <h4>
                                        <a target="_blank" href="https://iimt.ch">
                                            AutoLearnify origins from a thesis written at the international institute of management in technology (iimt).
                                        </a>
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-footer">
                                        <div class="stats">
                                                <span>
                                                    <i class="now-ui-icons design_app"></i>Origin
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 px-0">
                        <card type="background" class="card-fashion"
                              style="background-image: url('/img/pages/guide/Cloze-Test-Gen-iPad-1000.png')">
                            <div class="card-title text-left">
                                <h2>
                                    <a href="#">This site is under construction...</a>
                                </h2>
                            </div>
                            <div class="card-footer text-left">
                                <div class="stats">
                                    <span>
                                        <i class="now-ui-icons media-2_sound-wave"></i>This site is under construction...
                                    </span>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: ""
      }
    };
  }
};
</script>
<style>
</style>
